const TblDateFormat = (dateString) => {
    if (!dateString) return ""; // Handle empty or undefined input
    
    const date = new Date(dateString);
    const today = new Date();
    
    // Get time difference in milliseconds
    const diffTime = today - date;
    
    // Calculate the difference in days
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
    if (diffDays === 0) {
      return "Today";
    } else if (diffDays === 1) {
      return "Yesterday";
    } else if (diffDays < 30) {
      return `${diffDays} days ago`;
    } else {
      const year = date.getFullYear();
      const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
      const month = monthNames[date.getMonth()];
      const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed
      
      return `${month}-${day}-${year}`;
    }
  };
  
  export { TblDateFormat };
  