const formatAmount = (amount, fraction = 2, isThousand = false) => {
  if (typeof amount !== "number" || isNaN(amount)) return "0.00"; // Handle invalid input

  let formattedAmount = amount;

  if (isThousand) {
    if (amount <= 1000) {
      return amount.toFixed(fraction);
    }
    formattedAmount = amount / 1000;
    return (
      formattedAmount.toLocaleString("en-US", {
        minimumFractionDigits: fraction,
        maximumFractionDigits: fraction,
      }) + "k"
    );
  }

  return amount.toLocaleString("en-US", {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  });
};

export default formatAmount;
