import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const MultiSelectAutocomplete = ({
  options,
  error,
  property,
  label,
  selectedOptions,
  setSelectedOptions,
  fullList
}) => {
  const handleStaffAutocompleteChange = (event, newValue) => {
    setSelectedOptions(newValue.map((company) => company.UserId));
  };
  useEffect(() => {
   console.log("first",selectedOptions)
  }, [selectedOptions])
  
  return (
    <>
      <label className="form-label">
        {label}
        <span className="text-danger">*</span>
      </label>
      <Autocomplete
        id="staff-autocomplete"
        size="small"
        multiple
        options={options}
        getOptionLabel={(option) =>
          option.FirstName + " " + option.LastName || ""
        }
        value={fullList.filter((company) =>
          selectedOptions.includes(company.UserId)
        )}
        onChange={handleStaffAutocompleteChange}
        isOptionEqualToValue={(option, value) =>
          option.UserId === value[property]
        }
        renderOption={(props, option) => (
          <li {...props}>
            <div className="customer-dd-border">
              <div className="row">
                <div className="col-md-auto">
                  <h6 className="pb-0 mb-0">
                    {option.FirstName} {option.LastName}
                  </h6>
                </div>
                <div className="col-md-auto">
                  <small>
                    {"("}
                    {option.Role}
                    {")"}
                  </small>
                </div>
              </div>
            </div>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            error={error && selectedOptions.length <= 0}
            placeholder="Choose..."
            className="bg-white"
          />
        )}
      />
    </>
  );
};

export default MultiSelectAutocomplete;
