import React, { useContext } from "react";
import ServiceRequestTR from "./ServiceRequestTR";
import { useEffect, useState } from "react";
import StatusCards from "./StatusCards";
import { Form } from "react-bootstrap";
import "datatables.net";
import Cookies from "js-cookie";
import useFetchServiceRequests from "../Hooks/useFetchServiceRequests";
import { DataContext } from "../../context/AppData";

const ServiceRequests = () => {
  const {
    isLoading,
    fetchServiceRequest,
    totalRecords,
    fetchFilterServiceRequest,
    sRFilterList,
    serviceRequest,
    sRfetchError,
  } = useFetchServiceRequests();

  const token = Cookies.get("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const userdata = Cookies.get("userData");
  const [showCards, setShowCards] = useState(true);

  // const [locationOptions, setLocationOptions] = useState();
  const [open, setOpen] = useState(0);
  const [closed, setClosed] = useState(0);
  const { statusId, setStatusId } = useContext(DataContext);

  useEffect(() => {
    // Filter the estimates array to get only the entries with Status === "Pending"
    const pendingEstimates = serviceRequest.filter(
      (estimate) => estimate.Status === "Open"
    );
    const pendingClosed = serviceRequest.filter(
      (estimate) => estimate.Status === "Closed"
    );

    // Update the state variable with the number of pending estimates
    setOpen(pendingEstimates.length);
    setClosed(pendingClosed.length);
  }, [serviceRequest]);

  useEffect(() => {
    return () => {
      setStatusId(0);
    };
  }, []);

  return (
    <>
      <div className="container-fluid pt-3">
        <div className="row " style={{height : "9em"}}>
          {showCards && (
            <StatusCards
              setStatusId={setStatusId}
              statusId={statusId}
              totalRecords
              newData={1178}
              open={totalRecords.totalOpenRecords}
              closed={totalRecords.totalClosedRecords}
              total={78178}
            />
          )}
        </div>

        <div>
          <ServiceRequestTR
            sRfetchError={sRfetchError}
            headers={headers}
            serviceRequest={serviceRequest}
            setShowCards={setShowCards}
            statusId={statusId}
            fetchFilterServiceRequest={fetchFilterServiceRequest}
            sRFilterList={sRFilterList}
            totalRecords={totalRecords}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default ServiceRequests;
