import React, { useEffect, useState } from "react";
import useGetData from "../Hooks/useGetData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { NavLink } from "react-router-dom";
import { Chip } from "@mui/material";

const MultiSelectDD = ({
  property1,
  property2,
  endPoint,
  placeholder,
  setPayload,
  payload,
  nav,
}) => {
  const { getListData, data } = useGetData();
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (endPoint !== "") {
      getListData(`${endPoint}?Search=${search}`);
    }
    console.log("Search text:", search);
  }, [search]);

  const handleChipChange = (event, newValue) => {
    console.log("newValue", newValue);
    if (newValue) {
        setPayload([...payload , newValue])
    }
    // return;
    // const newPayload = [...payload];
    // if (newValue) {
    //   newValue.forEach((item) => {
    //     const existingIndex = newPayload.findIndex(
    //       (payloadItem) => payloadItem[property1] === item[property1]
    //     );
    //     if (existingIndex !== -1) {
    //       newPayload[existingIndex] = item;
    //     } else {
    //       newPayload.push(item);
    //     }
    //   });
    // }
    // setPayload(newPayload);
  };

  const handleChipDelete = (option, index) => {
    const newPayload = [...payload];
    newPayload.splice(index, 1);
    setPayload(newPayload);
  };

  return (
    <>
      <Autocomplete
        size="small"
        options={data}
        filterOptions={(option) => option}
        getOptionLabel={(option) => option[property2] || ""}
        noOptionsText="No record found in system"
        onChange={handleChipChange}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => setSearch(e.target.value)}
            label=""
            placeholder={placeholder}
            className="bg-white"
          />
        )}
        aria-label="Contact select"
      />
      
      {/* {payload.map((option, index) =>
        option[property1] ? (
          <Chip
            key={option[property1]}
            sx={{marginTop : "5px", marginRight : "3px"}}
            label={
              <NavLink to={`${nav}${option[property1]}`}>
                {option[property2]}
              </NavLink>
            }
            size="small"
            deleteIcon={<span className="fa fa-times"></span>}
            onDelete={(event) => {
              handleChipDelete(option, index);
            }}
          />
        ) : (
          <></>
        )
      )} */}
    </>
  );
};

export default MultiSelectDD;
