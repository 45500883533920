import React from "react";

const TitleBar = ({ icon, title }) => {
  return (
    <div style={{height : "40px"}}>
    <div className="page-titles" style={{position : "fixed", width : "100%", }}>
      <ol className="breadcrumb">
        <div className="menu-icon  me-2">{icon}</div>
        <li>
          <h5 className="bc-title ">{title}</h5>
        </li>
      </ol>
    </div></div>
  );
};

export default TitleBar;
