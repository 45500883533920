function truncateString(str, length) {
  if (str) {
    if (str.length <= length) {
      return str;
    } else {
      return str.slice(0, length) + "...";
    }
  }
}
export default truncateString;
