import React, { useState, useEffect, useCallback } from "react";

import InvoiceTitleBar from "./InvoiceTitleBar";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TableContainer,
  TableSortLabel,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import useFetchInvoices from "../Hooks/useFetchInvoices";
import { NavLink, useNavigate } from "react-router-dom";
import { useEstimateContext } from "../../context/EstimateContext";
import TblDateFormat from "../../custom/TblDateFormat";
import AddButton from "../Reusable/AddButton";
import formatAmount from "../../custom/FormatAmount";
import ComaSpacing from "../../custom/ComaSpacing";
import ArrowOutwardIcon from "@mui/icons-material/OpenInNew";
import debounce from "lodash.debounce";
import formatDate from "../../custom/FormatDate";

const Invoices = () => {
  const navigate = useNavigate();
  const {
    invoiceList,
    loading,
    error,
    fetchInvoices,
    fetchFilterInvoice,
    filteredInvoiceList,
    totalRecords,
  } = useFetchInvoices();

  const currentDate = new Date();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const { setEstimateLinkData } = useEstimateContext();

  useEffect(() => {
    // fetchInvoices();
    setEstimateLinkData({});
  }, []);

  const [tablePage, setTablePage] = useState(0);
  const [statusId, setStatusId] = useState(0);
  const [search, setSearch] = useState("");
  const [isAscending, setIsAscending] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(formatDate(currentDate));
  const [orderBy, setOrderBy] = useState({
    Profit: false,
    issueDate : true
  });
  const [isIssueDate, setIsIssueDate] = useState(true);
  const debouncedGetFilteredInvoices = useCallback(
    debounce(fetchFilterInvoice, 500),
    []
  );
  useEffect(() => {
    debouncedGetFilteredInvoices(
      search,
      tablePage + 1,
      rowsPerPage,
      statusId,
      isAscending,
      orderBy.issueDate,
      orderBy.Profit,
      startDateFilter,
      endDateFilter,
    );
  }, [
    search,
    tablePage,
    rowsPerPage,
    statusId,
    isAscending,
    isIssueDate,
    orderBy,
    startDateFilter,
    endDateFilter,
  ]);

  const handleChangePage = (event, newPage) => {
    setTablePage(newPage);
  };

  return (
    <>
      <InvoiceTitleBar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12" id="bootstrap-table2">
            <div className="card">
              <>
                <div className="card-header flex-wrap d-flex justify-content-between  border-0">
                  <div>
                    <TextField
                      label="Search Invoices"
                      variant="standard"
                      size="small"
                      style={{ width: "15em" }}
                      fullWidth
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    {/* <CalendarMonthOutlinedIcon /> */}
                <TextField
                  label={"Start Date"}
                  placeholder="Start Date"
                  variant="standard"
                  className="me-3"
                  size="small"
                  type="date"
                  value={formatDate(startDateFilter)}
                  onChange={(e) => setStartDateFilter(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                {/* <CalendarMonthOutlinedIcon /> */}
                <TextField
                  label={"End Date"}
                  placeholder="Start Date"
                  variant="standard"
                  className="me-2"
                  size="small"
                  type="date"
                  value={formatDate(endDateFilter)}
                  onChange={(e) => setEndDateFilter(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                  </div>
                  <div className=" pe-2">
                    <FormControl className=" me-3" variant="outlined">
                      <Select
                        labelId="customer-type-label"
                        variant="outlined"
                        value={isAscending}
                        size="small"
                      >
                        <MenuItem
                          value={true}
                          onClick={() => {
                            setIsAscending(true);
                          }}
                        >
                          Ascending
                        </MenuItem>
                        <MenuItem
                          value={false}
                          onClick={() => {
                            setIsAscending(false);
                          }}
                        >
                          Descending
                        </MenuItem>
                        <ListSubheader>Sort By</ListSubheader>
                        <MenuItem
                          onClick={() => {
                            setOrderBy((prevSate) => ({
                              ...prevSate,
                              Profit: true,
                              issueDate : true
                            }));
                            setIsAscending(!isAscending);
                          }}
                        >
                          <>
                            <Checkbox checked={orderBy.issueDate} /> Issue Date
                          </>{" "}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOrderBy((prevSate) => ({
                              ...prevSate,
                              Profit: true,
                              issueDate : false
                            }));
                            setIsAscending(!isAscending);
                          }}
                        >
                          {" "}
                          <>
                            <Checkbox checked={!orderBy.issueDate} /> Created Date
                          </>
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <AddButton
                      onClick={() => {
                        navigate(`/invoices/add-invoices`);
                      }}
                    >
                      Add Invoice
                    </AddButton>
                  </div>
                </div>

                <div className="card-body pt-0">
                  <TableContainer sx={{ overflowX: "auto" }}>
                    <Table>
                      <TableHead className="table-header">
                        <TableRow className="bill-tbl-alignment">
                          <TableCell>Invoice</TableCell>
                          <TableCell>Issue Date</TableCell>
                          <TableCell>Customer</TableCell>

                          <TableCell>Estimate#</TableCell>
                          <TableCell>Bill#</TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy.Profit}
                              direction={isAscending ? "asc" : "desc"}
                              onClick={() => {
                                setOrderBy((prevSate) => ({
                                  ...prevSate,
                                  Profit: true,
                                  issueDate : false
                                }));
                                setIsAscending(!isAscending);
                              }}
                            >
                              Profit%
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className="text-end">Balance</TableCell>
                          <TableCell className="text-end">Total</TableCell>
                          {/* <TableCell>Status</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <TableRow>
                            <TableCell colSpan={12} className="text-center">
                              <div className="center-loader">
                                <CircularProgress
                                  style={{ color: "#789a3d" }}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            <TableRow>
                              {error ? (
                                <TableCell className="text-center" colSpan={9}>
                                  <div className="text-center">
                                    No Record Found
                                  </div>
                                </TableCell>
                              ) : null}
                            </TableRow>

                            {filteredInvoiceList
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((invoice, index) => (
                                <TableRow
                                  className="bill-tbl-alignment"
                                  hover
                                  key={index}
                                >
                                  <TableCell
                                    onClick={() => {
                                      navigate(
                                        `/invoices/add-invoices?id=${invoice.InvoiceId}`
                                      );
                                    }}
                                  >
                                    {invoice.InvoiceNumber}
                                  </TableCell>
                                  <TableCell
                                    onClick={() => {
                                      navigate(
                                        `/invoices/add-invoices?id=${invoice.InvoiceId}`
                                      );
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {TblDateFormat(invoice.IssueDate)}
                                  </TableCell>
                                  <TableCell
                                  // onClick={() => {
                                  //   navigate(
                                  //     `/invoices/add-invoices?id=${invoice.InvoiceId}`
                                  //   );
                                  // }}
                                  >
                                    {invoice.CustomerDisplayName}{" "}
                                    <NavLink
                                      to={`/customers/add-customer?id=${invoice.CustomerId}`}
                                      target="_blank"
                                    >
                                      <ArrowOutwardIcon
                                        style={{ fontSize: 14 }}
                                      />
                                    </NavLink>
                                  </TableCell>

                                  <TableCell
                                    onClick={() => {
                                      navigate(
                                        `/invoices/add-invoices?id=${invoice.InvoiceId}`
                                      );
                                    }}
                                  >
                                    {invoice.EstimateNumber}
                                  </TableCell>
                                  <TableCell
                                    style={{ width: "10%" }}
                                    onClick={() => {
                                      navigate(
                                        `/invoices/add-invoices?id=${invoice.InvoiceId}`
                                      );
                                    }}
                                  >
                                    {ComaSpacing(invoice.BillNumber)}
                                  </TableCell>
                                  <TableCell
                                    onClick={() => {
                                      navigate(
                                        `/invoices/add-invoices?id=${invoice.InvoiceId}`
                                      );
                                    }}
                                  >
                                    {invoice.ProfitPercentage?.toFixed(2)}
                                  </TableCell>
                                  <TableCell
                                    className="text-end"
                                    onClick={() => {
                                      navigate(
                                        `/invoices/add-invoices?id=${invoice.InvoiceId}`
                                      );
                                    }}
                                  >
                                    ${formatAmount(invoice.BalanceAmount)}
                                  </TableCell>
                                  <TableCell
                                    className="text-end"
                                    onClick={() => {
                                      navigate(
                                        `/invoices/add-invoices?id=${invoice.InvoiceId}`
                                      );
                                    }}
                                  >
                                    ${formatAmount(invoice.TotalAmount)}
                                  </TableCell>
                                  {/* <TableCell>
                                <span
                                  onClick={() => {
                                    // setInvoiceData(invoice);
                                    navigate(
                                      `/invoices/invoice-preview?id=${invoice.InvoiceId}`
                                    );
                                  }}
                                  className="  span-hover-pointer badge badge-pill badge-success "
                                >
                                  Open
                                </span>
                              </TableCell> */}
                                </TableRow>
                              ))}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[100, 200, 300]}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={tablePage} // Use tablePage for the table rows
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(parseInt(event.target.value, 10));
                      setTablePage(0); // Reset the tablePage to 0 when rowsPerPage changes
                    }}
                  />
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoices;
