import React from "react";
import { useNavigate, NavLink } from "react-router-dom";

const LinkingBadges = ({
  data,
  setData,
  col1 = {
    header: "PO#",
    number: "PurchaseOrderNumber",
    id: "PurchaseOrderId",
    to: "/purchase-order/add-po?id=",
  },
  col2 =  {
    header: "Bill#",
    number: "BillNumber",
    id: "BillId",
    to: "/bills/add-bill?id=",
  },
  allowRemove=true
}) => {
  const navigate = useNavigate();

  const handleChipDelete = (option, index) => {
    const newPayload = [...data];
    newPayload.splice(index, 1);
    setData(newPayload);
  };

  return (
    <>
      <div
        className="mb-1 row"
        style={{
          borderBottom: "1px solid #cccccc",
        }}
      >
        <div className=" col col-md-5 col col-sm-5 pe-0 pe-0 my-1">
          <p
            className="py-0  text-black"
            style={{ lineHeight: 1, cursor: "pointer", fontWeight: "bold" }}
          >
            {col1.header}
          </p>
        </div>
        <div className=" col col-md-1 col col-sm-1 px-0 my-1">
          <p className="py-0 text-black" style={{ lineHeight: 1 }}>
            -
          </p>
        </div>
        <div className="col col-md-5 col col-sm-5 pe-0 my-1">
          <p
            className="py-0  text-black"
            style={{ lineHeight: 1, cursor: "pointer", fontWeight: "bold" }}
          >
            {col2.header}
          </p>
        </div>
        <div className=" col col-md-1 col col-sm-1 pe-0 my-1"></div>
      </div>
      {data.map((item, index) => (
        <div
          key={index}
          className="row  mb-1"
          style={{
            borderBottom: "1px solid #cccccc",
            paddingRight: 0,
          }}
        >
          <div
            className=" col col-md-5 col col-sm-5 pe-0 my-1 "
            style={{ alignContent: "center" }}
          >
            <NavLink to={`${col1.to}${item[col1.id]}`}>
              <p
                className="py-0  text-black"
                style={{
                  lineHeight: 1,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                {item[col1.number]}
              </p>
            </NavLink>
          </div>
          <div className="col col-md-1 col col-sm-1 px-0 my-1">
            <p className="py-0 text-black" style={{ lineHeight: 1 }}>
              -
            </p>
          </div>
          <div
            className="col col-md-5 col col-sm-5 pe-0 my-1"
            style={{ alignContent: "center" }}
          >
            <NavLink to={`${col2.to}${item[col2.id]}`}>
              <p
                className="py-0  text-black"
                style={{
                  lineHeight: 1,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                {item[col2.number]}
              </p>
            </NavLink>
          </div>
          <div className="col col-md-1 col col-sm-1 px-0 my-1 text-center">
        {allowRemove && <span
          className="fa fa-times"
          style={{
            backgroundColor: "#ccc",
            padding: "2px 4px",

            borderRadius: "50px",
            cursor: "pointer",
          }}
          onClick={handleChipDelete}
        ></span>}
          </div>
        </div>
      ))}
    </>
  );
};

export default LinkingBadges;
